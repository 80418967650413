import React from 'react'
import PropTypes from 'prop-types'


export default function About() {
  return (
    <div className='container'>
        <h1>This is about us page.</h1>
        {console.log("This is about us page")}
    </div>
  )
}


