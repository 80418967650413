/* import logo from './logo.svg'; */
import React from 'react'
import Navbar from './components/Navbar';
import Home from './components/Home';
import TextForm from './components/TextForm';
import About from './components/About';
import Offices from './components/Offices';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";


function App() {
  return (
    <> 
    <Router>    
    <Navbar title = 'Pioneer Gov' aboutUs = 'About Us'/>
    <Routes>  
          <Route exact path='/' element={<Home/>} />      
          <Route exact path='/text-form' element={<TextForm heading = 'Enter text to analyze.'/>} />      
          <Route exact path='/about' element={<About />} />     
          <Route exact path='/offices/:slug' element={<Offices />} />     
          
    </Routes>
    
   </Router> 
    </>
  );
}

export default App;
