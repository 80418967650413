import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'


export default function Home() {
    const [office, setOffice] = useState([]); 
    const [officials, setOfficials] = useState([]); 
    
    useEffect(() => {
        const url = "https://www.api.pg.softhill.dev/offices/us-senator-south-carolina?key=likfji74@u5l$kdfh";

        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                console.log(json.office.name);
                setOffice(json.office.name);
                setOfficials(json.officials);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <div className='container'>
                <h1>This is Home page.</h1>
            </div>
            <div className='container'>  
                <h2>Office</h2>  
                <p>{office}  </p> 
                {officials.map((element)=>{console.log(element)})}           
                          
                          
            </div>
        </>
    )
}