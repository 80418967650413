import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'

const Offices = () => {
    const params = useParams();
    const slug = params.slug;    
    let [office, setOffice] = useState([]); 
    let [officials, setOfficials] = useState([]); 
    
    
    useEffect(() => { 
              
        const url = "https://www.api.pg.softhill.dev/offices/"+ slug +"?key=likfji74@u5l$kdfh";

        const fetchData = async () => {
            try {
                axios.get(url)
            .then(res=>{
                console.log('Response from main API: ',res);
                let office = res.data.office; 
                setOffice(office);
                console.log('Type of office: ', typeof office);
                
                console.log('Name: ',office.name);     
                console.log('State: ',office.state);     
                console.log('Division: ',office.division);     
                console.log('Levels: ',office.levels);     
                console.log('Roles: ',office.roles);     
                
                console.log('Office  Data: ',office);
                let officials = res.data.officials; 
                setOfficials(officials);
                console.log(typeof officials);
                officials.forEach(myFunction);
                function myFunction(item, index) {
                    console.log(item);
                  } 
                console.log('another forloop');
                officials.forEach(element => console.log(element.basic_info.name));
                
            }) 

            } catch (error) {
                console.log("error", error);
            }
        };         
        fetchData();
    }, []);

    return (        
        <>
             
            <div className='container'>
                <h1>This details with the slug {slug}.</h1>
            </div>
            <div className='container'>  
                <h2>Office:</h2> 
                <hr/>
                <table className='table'>
                    <thead>
                        <tr>
                        <th>Name</th>
                        <th>State</th>
                        <th>Division</th>
                        <th>Levels</th>
                        <th>Roles</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{office.name}</td>
                        <td>{office.state}</td>
                        <td>{office.division}</td>
                        <td>{office.levels}</td>
                        <td>{office.roles}</td>
                        
                    </tr>
                    </tbody>
                </table>
                
                <h2>Officials:</h2> 
                <hr/>
                <div>

                {
                    officials.map((official) => {
                        console.log('inside html, ', official.basic_info.name);
                        let basic_info = official.basic_info;
                        let address = official.address;
                                               
                          return (
                            <>                            
                            <h4 className='mt-5'>Basic Information:</h4>
                            <hr/>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>State</th>
                                        <th>Party</th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{basic_info.name}</td>
                                    <td>{basic_info.state_name}</td>
                                    <td>{basic_info.party}</td>
                                </tr>
                                </tbody>
                            </table>
                            <h4 className='mt-3'>Address:</h4>
                            <hr/>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>State</th>
                                        <th>Party</th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{basic_info.name}</td>
                                    <td>{basic_info.state_name}</td>
                                    <td>{basic_info.party}</td>
                                </tr>
                                </tbody>
                            </table>
                            </>
                          )
                     
                    })
                }
                </div>
                          
            </div>
        </>
    )
}

export default Offices
