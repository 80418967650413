import React, {useState} from 'react'



export default function TextForm(props) {
    const [text, setText] = useState();
    const handleUpClick = (event) =>{
        setText(text.toUpperCase())
    }
    const handleOnChange = (event) =>{
        setText(event.target.value)
    }
    return (
    <>   
        <div className='container my-4'>
            <h2>{props.heading}</h2>
            <div className="mb-3">                
                <textarea className="form-control" onChange={handleOnChange} value={text} id="textBox" rows="8"></textarea>
            </div>
            <button onClick={handleUpClick} className="btn btn-primary">To Uppercase</button>
        </div>   
        
    </>
    )
}
